import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import FleaCategoryFilters from "@/game-eft/components/flea-market/FleaCategoryFilters.jsx";
import CategoryHeader from "@/game-eft/components/flea-market/FleaCategoryHeader.jsx";
import FleaItemTile from "@/game-eft/components/flea-market/FleaItemTile.jsx";
import FleaItemTileLoading from "@/game-eft/components/flea-market/FleaItemTileLoading";
import { itemWrapperCss } from "@/game-eft/components/flea-market/FleaMarket.styled.jsx";
import type { WikiCategory } from "@/game-eft/models/model-wiki.mjs";
import {
  getCategoriesBySlug,
  useFleaCategoryLinks,
} from "@/game-eft/utils/flea-market.mjs";
import useFleaMarketFilters from "@/game-eft/utils/use-flea-market-filters.mjs";
import { WikiDataContextProvider } from "@/game-eft/utils/use-wiki-data.jsx";
import { getParentCategoryTranslation } from "@/game-eft/utils/utils.mjs";
import PageContainer from "@/shared/PageContainer";
import PageHeader from "@/shared/PageHeader";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TITLE = ["eft:meta.fleaMarket.title", "Flea Market"] as const;
const DESCRIPTION = [
  "eft:meta.fleaMarket.description",
  "Maximize your profits in Escape from Tarkov with Blitz.gg’s Flea Market tool, featuring real-time price averages, trader listings, profit margins, and more. Stay ahead of the market trends and make smarter trading decisions with up-to-date insights.",
] as const;

const FEATURED_WEAPONS = [
  "5beed0f50db834001c062b12",
  "5ae08f0a5acfc408fb1398a1",
  "5ac66cb05acfc40198510a10",
  "5a7ae0c351dfba0017554310",
];
const FEATURED_AMMO = [
  "57a0dfb82459774d3078b56c",
  "54527a984bdc2d4e668b4567",
  "5656d7c34bdc2d9d198b4587",
  "5887431f2459777e1612938f",
];
const FEATURED_MEDS = [
  "544fb45d4bdc2dee738b4568",
  "60098ad7c2240c0fe85c570a",
  "5d02778e86f774203e7dedbe",
  "5755383e24597772cb798966",
];
const FEATURED_GEAR = [
  "609e8540d5c319764c2bc2e9",
  "5c0e655586f774045612eeb2",
  "5b40e1525acfc4771e1c6611",
  "5b4329f05acfc47a86086aa1",
];

interface CategoryProps {
  categories: [string, WikiCategory][] | null;
}

function Title({ categories }: CategoryProps) {
  const { t } = useTranslation();

  if (categories && categories.length) {
    const categoryName =
      categories.length > 1 && categories[0][1]?.parent
        ? getParentCategoryTranslation(categories[0][1].parent)
        : categories[0][1].name;

    return <span>{t(...categoryName)}</span>;
  }
  return <span>{t(...TITLE)}</span>;
}

function FleaMarketOverview() {
  return (
    <WikiDataContextProvider>
      <div className="flex column gap-4">
        <CategoryHeader category="Weapons" />
        <div className={itemWrapperCss()}>
          {FEATURED_WEAPONS.map((weaponId) => (
            <FleaItemTile itemId={weaponId} key={weaponId} />
          ))}
        </div>
        <CategoryHeader category="Ammunition" />
        <div className={itemWrapperCss()}>
          {FEATURED_AMMO.map((ammoId) => (
            <FleaItemTile itemId={ammoId} key={ammoId} />
          ))}
        </div>
        <CategoryHeader category="Meds" />
        <div className={itemWrapperCss()}>
          {FEATURED_MEDS.map((medId) => (
            <FleaItemTile itemId={medId} key={medId} />
          ))}
        </div>
        <CategoryHeader category="Gear" />
        <div className={itemWrapperCss()}>
          {FEATURED_GEAR.map((gearId) => (
            <FleaItemTile itemId={gearId} key={gearId} />
          ))}
        </div>
      </div>
    </WikiDataContextProvider>
  );
}

function FleaMarketCategory({ categories }: CategoryProps) {
  const catNames = useMemo(
    () => categories?.map(([catName]) => catName),
    [categories],
  );

  const {
    fleaData,
    searchTerm,
    setSearchTerm,
    sortDirection,
    setSortDirection,
  } = useFleaMarketFilters({ selectedCategories: catNames ?? [] });
  const isLoaded = fleaData && catNames && catNames.length;

  return (
    <WikiDataContextProvider>
      <div className="flex column gap-4">
        <FleaCategoryFilters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
        <div className={itemWrapperCss()}>
          {isLoaded
            ? fleaData.map((v) => <FleaItemTile itemId={v.id} key={v.id} />)
            : Array(16)
                .fill(null)
                .map((_, idx) => {
                  return <FleaItemTileLoading key={idx} />;
                })}
        </div>
      </div>
    </WikiDataContextProvider>
  );
}

function Main() {
  const {
    parameters: [category],
  } = useRoute();
  const state = useSnapshot(readState);

  const categories = useMemo(() => {
    if (!state.eft.wiki || !category) return null;
    return getCategoriesBySlug(category);
  }, [category, state.eft.wiki]);
  const links = useFleaCategoryLinks();

  return (
    <PageContainer style={{ "--item-width": "calc(var(--sp-10) * 5)" }}>
      <PageHeader
        title={() => <Title categories={categories} />}
        links={links}
        linksLoading={!links?.length}
      />
      {category ? (
        <FleaMarketCategory categories={categories} key={category} />
      ) : (
        <FleaMarketOverview />
      )}
    </PageContainer>
  );
}

export function meta(): Meta {
  return {
    title: TITLE,
    description: DESCRIPTION,
  };
}

export default Main;

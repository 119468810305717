import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SelectValue } from "clutch/src/Select/Select.jsx";
import type { TFunction } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { MIN_STRING_DISTANCE } from "@/app/constants.mjs";
import type { FleaMarketItemStats } from "@/game-eft/models/flea-market.mjs";
import type { WikiItem } from "@/game-eft/models/model-wiki.mjs";
import { useWikiData } from "@/game-eft/utils/use-wiki-data.jsx";
import { useQuery } from "@/util/router-hooks.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { timeSlice } from "@/util/time-slice.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export type SortDirections =
  | "priceAsc"
  | "priceDesc"
  | "alphaAsc"
  | "alphaDesc";

export interface CombinedFleaStats {
  id: string;
  item: WikiItem;
  fleaStats: FleaMarketItemStats;
  score: number;
}

export const SortSelectOptions: Record<
  SortDirections,
  SelectValue<SortDirections>
> = {
  priceAsc: {
    value: "priceAsc",
    text: ["eft:fleaMarket.sort.priceAsc", "Lowest Price"],
  },
  priceDesc: {
    value: "priceDesc",
    text: ["eft:fleaMarket.sort.priceDesc", "Highest Price"],
  },
  alphaAsc: {
    value: "alphaAsc",
    text: ["eft:fleaMarket.sort.alphaAsc", "Alphabetical: A-Z"],
  },
  alphaDesc: {
    value: "alphaDesc",
    text: ["eft:fleaMarket.sort.alphaDesc", "Alphabetical: Z-A"],
  },
};

const alphaSort =
  (t: TFunction) => (a: CombinedFleaStats, b: CombinedFleaStats) => {
    const { name: aName } = a.item;
    const { name: bName } = b.item;
    return t(...aName).localeCompare(t(...bName));
  };

const priceSort =
  (_t: TFunction) => (a: CombinedFleaStats, b: CombinedFleaStats) => {
    const aPrice = a.fleaStats.last_3_hour_avg;
    const bPrice = b.fleaStats.last_3_hour_avg;
    return aPrice - bPrice;
  };

function scoreItemForTerm(t: TFunction, item: WikiItem, term: string): number {
  let score = 0;
  if (stringCompare(term, t(...item.name)) > MIN_STRING_DISTANCE) score += 5;
  if (stringCompare(term, t(...item.short)) > MIN_STRING_DISTANCE) score += 5;
  if (
    item.meta?.caliber &&
    stringCompare(term, item.meta.caliber) > MIN_STRING_DISTANCE
  )
    score += 3;
  // if (compareStr(item.category, term)) score += 3;
  // if (compareStr(t(...item.description), term)) score += 1;
  return score;
}

const SortByDirection: Record<
  SortDirections,
  (t: TFunction) => (a: CombinedFleaStats, b: CombinedFleaStats) => number
> = {
  alphaAsc: (t) => alphaSort(t),
  alphaDesc: (t) => (a, b) => alphaSort(t)(b, a),
  priceAsc: (t) => priceSort(t),
  priceDesc: (t) => (a, b) => priceSort(t)(b, a),
};

export default function useFleaMarketFilters({
  selectedCategories,
}: {
  selectedCategories: string[];
}) {
  const { eft } = useSnapshot(readState);
  const [fleaData, setFleaData] = useState<CombinedFleaStats[] | null>(null);
  const [searchTerm, setSearchTerm] = useQuery("q", "");
  const [sortDirection, setSortDirection] = useQuery<SortDirections>(
    "sort",
    "priceAsc",
  );
  const { t } = useTranslation();
  const { items } = useWikiData();
  const { fleaMarket } = eft;

  const filterData = useCallback(() => {
    if (!fleaMarket) return null;
    const fleaData = Object.entries<FleaMarketItemStats>(fleaMarket)
      .reduce<CombinedFleaStats[]>((acc, [id, fleaStats]) => {
        const item = items?.[id];
        if (!item) return acc;
        if (
          !selectedCategories.includes(item.category) &&
          !selectedCategories.includes(item.subCategory)
        )
          return acc;

        acc.push({
          id,
          item,
          fleaStats,
          score:
            searchTerm?.length > 0 ? scoreItemForTerm(t, item, searchTerm) : 1,
        });

        return acc;
      }, [])
      .filter((data) => data.score >= 1)
      .sort(SortByDirection[sortDirection](t));

    return fleaData;
  }, [searchTerm, sortDirection, selectedCategories, items, fleaMarket]);

  useEffect(() => {
    let discardResults = false;
    setFleaData(null);
    timeSlice.run(filterData).then((data) => {
      if (!discardResults) setFleaData(data);
    });
    return () => {
      discardResults = true;
    };
  }, [searchTerm, sortDirection, selectedCategories, items, fleaMarket]);

  return {
    fleaData,
    searchTerm,
    setSearchTerm,
    sortDirection,
    setSortDirection,
  };
}
